// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import $ from "jquery";


//
//
// MOBILE MENUE
document.addEventListener('turbo:load', () => {
  $('#navbar_main ul li.dropdown > a').click(function(e) {
    if ($(window).width() <= 991.98){
      if ($(this).next('ul').is(':hidden') ) {
        $('#navbar_main ul li.dropdown ul').hide();
        $(this).next('ul').show();
      } else {
        $(this).next('ul').hide();
      }

      e.preventDefault();
    }
  });
});
